import classBind from 'classnames/bind'
import styles from './App.scss'
import { BrowserRouter as Router} from "react-router-dom";
import routes from './router'
import StaticRouter from './router/StaticRouter'

const cx = classBind.bind(styles)

function App() {
  return (
    <Router basename={''} forceRefresh={false}>
      <StaticRouter routes={routes}></StaticRouter>
    </Router>
  );
}

export default App;
