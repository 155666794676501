import universal from 'react-universal-component';
const Test = universal(() => import("../pages/Test"));
const Home = universal(() => import("../pages/Home"));
const About = universal(() => import("../pages/About"));



const routes = [
    {
        path: '/about',
        component: About
    },
    {
        path: '/test',
        component: Test
    },
    {
        path: '/',
        component: Home
    }
]


export default routes
